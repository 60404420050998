import { graphql } from 'gatsby';
import React from 'react';
import { useAnalyticsPageVariables } from 'helpers/useAnalyticsPageVariables';
import ErrorPage, { ErrorPageProps } from 'templates/ErrorPage';

export const query = graphql`
  query {
    csErrorPage(error_id: { eq: "bw-404" }) {
      ...ErrorPage
    }
  }
`;

const NotFoundPage: React.FC<ErrorPageProps> = ({ data }) => {
  useAnalyticsPageVariables(
    {
      pageCategories: [],
      pageType: 'ErrorPage',
      pageError: 404,
    },
    data.csErrorPage.blocks
  );
  return <ErrorPage data={data} />;
};

export default NotFoundPage;
